var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.maintenanceRequestData.ref, items: _vm.items }
      }),
      _vm.maintenanceRequestData.statusPlain == "draft"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _vm.maintenanceRequestData.statusPlain == "draft"
                    ? _c(
                        "div",
                        { staticClass: "button-items" },
                        [
                          _vm.maintenanceRequestData.statusPlain == "draft" &&
                          _vm.$can("edit_mr")
                            ? _c(
                                "b-button",
                                { attrs: { variant: "primary" } },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-edit font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Modifier ")
                                ]
                              )
                            : _vm._e(),
                          _vm.maintenanceRequestData.statusPlain == "draft" &&
                          _vm.$can("approve_mr")
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.approvalModal",
                                      modifiers: { approvalModal: true }
                                    }
                                  ],
                                  attrs: { variant: "success" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-check-double font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Approuver ")
                                ]
                              )
                            : _vm._e(),
                          _vm.maintenanceRequestData.statusPlain == "draft" &&
                          _vm.$can("reject_mr")
                            ? _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.rejectionModal",
                                      modifiers: { rejectionModal: true }
                                    }
                                  ],
                                  attrs: { variant: "danger" }
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "bx bx-block font-size-16 align-middle mr-2"
                                  }),
                                  _vm._v(" Rejeter ")
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void()" },
                  on: {
                    click: function($event) {
                      return _vm.fetchSingleMaintenanceRequestData()
                    }
                  }
                },
                [
                  _c("h1", [
                    _c("i", {
                      staticClass: "bx bx-rotate-right",
                      staticStyle: { float: "right" }
                    })
                  ])
                ]
              ),
              _c("h4", { staticClass: "card-title mb-4" }, [_vm._v("Détails")]),
              _c("p", { attrs: { lass: "text-muted mb-4" } }, [
                _vm._v(
                  " " + _vm._s(_vm.maintenanceRequestData.description) + " "
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "table-responsive mb-0" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Demandeur :")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.maintenanceRequestData.requestedBy)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Type de maintenance :")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.maintenanceRequestData.requestType)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Lieu de maintenance :")]),
                          _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.maintenanceRequestData.requestLocationType
                                ) +
                                " / " +
                                _vm._s(
                                  _vm.maintenanceRequestData.requestLocation
                                ) +
                                " "
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Urgence :")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.maintenanceRequestData.requestUrgency)
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ]),
                _c("div", { staticClass: "col-md-6" }, [
                  _c("div", { staticClass: "table-responsive mb-0" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("tbody", [
                        _c("tr", [
                          _c("th", [_vm._v("Date de demande :")]),
                          _c("td", [
                            _vm._v(
                              _vm._s(_vm.maintenanceRequestData.requestDate)
                            )
                          ])
                        ]),
                        _c("tr", [
                          _c("th", [_vm._v("Etat :")]),
                          _c("td", [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.maintenanceRequestData.status
                                )
                              }
                            })
                          ])
                        ]),
                        _vm.maintenanceRequestData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Rejeté par :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.maintenanceRequestData.rejectedBy)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.maintenanceRequestData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Date de rejet:")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.maintenanceRequestData.rejectionDate
                                  )
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.maintenanceRequestData.statusPlain == "rejected"
                          ? _c("tr", [
                              _c("th", [_vm._v("Raison de rejet:")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.maintenanceRequestData.rejectionReason
                                  )
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.maintenanceRequestData.statusPlain == "approved"
                          ? _c("tr", [
                              _c("th", [_vm._v("Approuvée par :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.maintenanceRequestData.approvedBy)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm.maintenanceRequestData.statusPlain == "approved"
                          ? _c("tr", [
                              _c("th", [_vm._v("Ordre de maintenance :")]),
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "maintenanceOrders.view",
                                          params: {
                                            uid:
                                              _vm.maintenanceRequestData
                                                .maintenanceOrder.uuid
                                          }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.maintenanceRequestData
                                            .maintenanceOrder.ref
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("approval-modal", {
        attrs: {
          modelUid: _vm.maintenanceRequestData.id,
          modelRef: _vm.maintenanceRequestData.ref
        }
      }),
      _c("rejection-modal", {
        attrs: {
          modelRef: _vm.maintenanceRequestData.ref,
          modelId: _vm.maintenanceRequestData.id,
          modelType: _vm.maintenanceRequestData.modelType
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }